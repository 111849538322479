import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, TextField, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { idb, updateLastBoxNotes } from "../../../firebase/firebase";

// ----------------------------------------------------------------------

AppLastBoxNotes.propTypes = {
  lastBoxNotesChild: PropTypes.string,
  setLastBoxNotesChild: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  snackStateChild: PropTypes.array,
  setSnackStateChild: PropTypes.func,
  loadingChild: PropTypes.bool,
};

export default function AppLastBoxNotes({ lastBoxNotesChild, setLastBoxNotesChild, name, email, snackStateChild, setSnackStateChild, loadingChild, ...other}) {
  return (
    <Card {...other} sx={{ p: 3, height: "100%" }}>
      {loadingChild ? (
        <>
        <Skeleton variant="rounded" width={'100%'} height={48.75} sx={{ mb: 3 }} animation="wave" />
        <Skeleton variant="rounded" width={"100%"} height={171.375} sx={{mb: 1.5}} animation="wave" />
        <Skeleton variant="rounded" width={'75%'} height={16.25} animation="wave" />
        </>
      ) : (
      <Stack
        direction="column"
        spacing={3}
        justifyContent="space-between"
      >
        <CardHeader sx={{ p: 0 }} title="Packing slip order notes" />
          <TextField
            sx={{h: "100% !important"}}
            id="outlined-multiline-static"
            label={`Order notes for ${name}`}
            multiline
            fullWidth
            rows={15}
            value={lastBoxNotesChild || ""}
            onChange={(event) => {
              setLastBoxNotesChild(event.target.value);
              updateLastBoxNotes(idb, email, event.target.value)
            }} 
            helperText="Notes are automatically saved."
            onBlur={(event) => {
              const snackStateCopy = [...snackStateChild];
              snackStateCopy[0] = true;
              snackStateCopy[1] = `Order notes successfully saved for "${name}".`;
              snackStateCopy[2] = "success";
              snackStateCopy[3] = "primary";
              setSnackStateChild(snackStateCopy);
            }}
          />
      </Stack>
      )}
    </Card>
  );
}