import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, CardContent, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Skeleton from '@mui/material/Skeleton';
// ----------------------------------------------------------------------

AppStripeInfo.propTypes = {
  shippingAddressChild: PropTypes.object,
  billingAddressChild: PropTypes.object,
  totalSpendChild: PropTypes.number,
  paidInvoicesChild: PropTypes.number,
  lastInvoiceAmountChild: PropTypes.number,
  lastInvoiceTimestampChild: PropTypes.number,
  loadingChild: PropTypes.bool,
};

export default function AppStripeInfo({
  shippingAddressChild,
  billingAddressChild,
  loadingChild,
  totalSpendChild,
  paidInvoicesChild,
  lastInvoiceAmountChild,
  lastInvoiceTimestampChild,
  ...other
}) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const convertUnixTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getYear().toString().slice(-2)}`;
  };

  return (
    <Card {...other} sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader
              sx={{ p: 0 }}
              title={'Shipping address'}
              subheader={
                shippingAddressChild !== null ? (
                  <>
                    {shippingAddressChild.name}
                    <br />
                    {shippingAddressChild.address.line1}
                    <br />
                    {shippingAddressChild.address.line2 !== '' && shippingAddressChild.address.line2 !== null && (
                      <>
                        {shippingAddressChild.address.line2}
                        <br />
                      </>
                    )}
                    {shippingAddressChild.address.city}
                    <span>, </span>
                    {shippingAddressChild.address.state}
                    <span> </span>
                    {shippingAddressChild.address.postal_code}
                    <br />
                    {shippingAddressChild.address.country}
                  </>
                ) : (
                  'No shipping details provided.'
                )
              }
            />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader
              sx={{ p: 0 }}
              title={'Billing address'}
              subheader={
                billingAddressChild !== null ? (
                  <>
                    {billingAddressChild.line1}
                    <br />
                    {billingAddressChild.line2 !== '' && billingAddressChild.line2 !== null && (
                      <>
                        {billingAddressChild.line2}
                        <br />
                      </>
                    )}
                    {billingAddressChild.city}
                    <span>, </span>
                    {billingAddressChild.state}
                    <span> </span>
                    {billingAddressChild.postal_code}
                    <br />
                    {billingAddressChild.country}
                  </>
                ) : (
                  'No billing details provided.'
                )
              }
            />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader
              sx={{ p: 0 }}
              title={'Total spend'}
              subheader={
                <>
                  <Box component="span" sx={{ display: 'inline-block', color: 'green', fontWeight: 700 }}>
                    {formatter.format(totalSpendChild / 100)}
                  </Box>{' '}
                  ({paidInvoicesChild} transaction{paidInvoicesChild > 1 && 's'})
                </>
              }
            />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader
              sx={{ p: 0 }}
              title={'Last invoice'}
              subheader={`${formatter.format(lastInvoiceAmountChild / 100)} on ${convertUnixTimestamp(
                lastInvoiceTimestampChild
              )}`}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
