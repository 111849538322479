import { Navigate, useRoutes } from 'react-router-dom';
import { useState, useEffect } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Construction from './pages/Construction';
import Restricted from './pages/Restricted';
import Inventory from './pages/Inventory';
import History from './pages/History';

// ----------------------------------------------------------------------

export default function Router() {
  // Current user global prop for search, NOT authentication.
  // TODO: Figure out how to change using Context API.
  const [currentUser, setCurrentUser] = useState('');

  // Authentication prop for conditional redirecting.
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Initialize global authorized user account object.
  const [authUserObj, setAuthUserObj] = useState({
    boxAccess: false,
    boxApproval: true,
    boxHistory: false,
    boxNotice: true,
    inventoryAdd: false,
    inventoryEdit: false,
    inventoryView: false,
    email: '',
    displayName: '',
    photoURL: '',
  });

  return useRoutes([
    {
      path: '/dashboard',
      element: isLoggedIn ? (
        <DashboardLayout setCurrentUserChild={setCurrentUser} authUserObjChild={authUserObj} />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        {
          path: 'app',
          element: authUserObj.boxAccess ? (
            <DashboardApp currentUserChild={currentUser} authUserObjChild={authUserObj} />
          ) : (
            <Restricted />
          ),
        },
        { path: 'customers', element: <Construction /> },
        {
          path: 'inventory',
          element: authUserObj.inventoryView ? <Inventory authUserObjChild={authUserObj} /> : <Restricted />,
        },
        {
          path: 'history',
          element: authUserObj.boxHistory ? <History /> : <Restricted />,
        },
        { path: 'documentation', element: <Construction /> },
      ],
    },
    {
      path: 'login',
      element: <Login setIsLoggedInChild={setIsLoggedIn} setAuthUserObjChild={setAuthUserObj} />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
