import * as React from 'react';
import PropTypes from "prop-types";
// @mui
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Card, CardHeader } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';

const filter = createFilterOptions();

AppInventorySearch.propTypes = {
    contentsChild: PropTypes.array,
    setContentsChild: PropTypes.func,
    snackStateChild: PropTypes.array,
    setSnackStateChild: PropTypes.func,
    inventoryCatalogChild: PropTypes.array,
    loadingChild: PropTypes.bool,
    receivedFiguresChild: PropTypes.array, 
    receivedMangaChild: PropTypes.array,
    receivedMiscChild: PropTypes.array,
    receivedSnacksChild: PropTypes.array,
};

export default function AppInventorySearch({ contentsChild, setContentsChild, snackStateChild, setSnackStateChild, inventoryCatalogChild, loadingChild, receivedFiguresChild, receivedMangaChild, receivedMiscChild, receivedSnacksChild, ...other}) {
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      sku: '',
      description: '',
      categories: '',
      quantity: 0,
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    sku: '',
    description: '',
    categories: '',
    quantity: 0,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      sku: dialogValue.sku,
      description: dialogValue.description,
      categories: dialogValue.categories,
      quantity: dialogValue.quantity,
    });

    handleClose();
  };

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      {loadingChild ? (
        <>
          <Skeleton variant="rounded" width={'100%'} height={48.75} sx={{mb: 1.5}} animation="wave" />
          <Skeleton variant="rounded" width={'40%'} height={16.25} animation="wave" />
        </>
      ) : ( 
        <>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                  sku: newValue,
                  description: '',
                  categories: '',
                  quantity: '',
                });
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue({
                sku: newValue.inputValue,
                description: '',
                categories: '',
                quantity: '',
              });
            } else {
              setValue(newValue);

              // Determine whether SKU has already been received by current user.
              const foundFigure = receivedFiguresChild.some(el => el.sku === newValue.sku);
              const foundManga = receivedMangaChild.some(el => el.sku === newValue.sku);
              const foundMisc = receivedMiscChild.some(el => el.sku === newValue.sku);
              const foundSnacks = receivedSnacksChild.some(el => el.sku === newValue.sku);

              if (newValue.quantity > 0) {
                // Item has never been received.
                if (!foundFigure && !foundManga && !foundMisc && !foundSnacks) {
                  // Not clearing value using right end adornment ('x' icon) and 
                  // contents does not already include item. ES6+ support only.
                  if (newValue !== null && !contentsChild.some(el => el.sku === newValue.sku)) {
                    setContentsChild(contentsChild => [...contentsChild, newValue])
                  } else if (contentsChild.some(el => el.sku === newValue.sku)) {
                    const snackStateCopy = [...snackStateChild];
                    snackStateCopy[0] = true;
                    snackStateCopy[1] = "Item already added to box. Cannot contain duplicates.";
                    snackStateCopy[2] = "error";
                    snackStateCopy[3] = "error";
                    setSnackStateChild(snackStateCopy);
                  }
                } else {
                  const snackStateCopy = [...snackStateChild];
                  snackStateCopy[0] = true;
                  snackStateCopy[1] = "User has received this item before. Select a new item.";
                  snackStateCopy[2] = "error";
                  snackStateCopy[3] = "error";
                  setSnackStateChild(snackStateCopy);
                }
              } else {
                const snackStateCopy = [...snackStateChild];
                snackStateCopy[0] = true;
                snackStateCopy[1] = "Item out of stock. Update its quantity in the Inventory page.";
                snackStateCopy[2] = "error";
                snackStateCopy[3] = "error";
                setSnackStateChild(snackStateCopy);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                sku: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          id="free-solo-dialog-demo"
          options={inventoryCatalogChild}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.sku;
          }}
          selectOnFocus
          clearOnBlur
          onBlur={(event) => {
            setValue({sku: "", description: "", categories: "", quantity: ""});
          }}
          handleHomeEndKeys
          renderOption={(props, option) => <li {...props}>{option.sku}</li>}
          sx={{ width: 1 }}
          freeSolo
          renderInput={(params) => <TextField {...params} helperText='Search for products to add by SKU (Ex. "figure-kny-tanjiro-mar.22").' label="Product Search" />}
        />
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Add a product</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Quickly add a new product to the catalog. Recommended adding through Inventory section.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.sku}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    sku: event.target.value,
                  })
                }
                label="SKU"
                type="text"
                variant="standard"
              />
              <TextField
                margin="dense"
                id="name"
                value={dialogValue.description}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    description: event.target.value,
                  })
                }
                label="Description"
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </Dialog>
        </>
      )}
    </Card>
  );
}