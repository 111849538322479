import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Card,
  Link,
  Container,
  Typography,
  Grid,
  IconButton,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  LinearProgress,
  TablePagination,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { BoxHistoryCard } from '../sections/@dashboard/history';
import { getBoxHistory, revertBox, getBoxHistoryCount, getUpdatedHistoryDoc } from '../firebase/firebase';
// ----------------------------------------------------------------------

export default function History() {
  const [loading, setLoading] = useState(true);
  const [docSnaps, setDocSnaps] = useState([]);
  const [lastVisible, setLastVisible] = useState(false);
  const [boxCount, setBoxCount] = useState(0);

  // On load, get box history.
  useEffect(() => {
    getBoxHistoryCount().then((resolvedCount) => {
      setBoxCount(resolvedCount);
      getBoxHistory().then((resolvedDocSnaps) => {
        // Get the last visible document
        setLastVisible(resolvedDocSnaps.docs[resolvedDocSnaps.docs.length - 1]);
        setDocSnaps(resolvedDocSnaps.docs);
        setLoading(false);
      });
    });
  }, []);

  // Product image popup URL.
  const [imagePopUp, setImagePopUp] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  // Snackbar state in the form [state, message, severity, color].
  const [snackState, setSnackState] = useState([false, 'Something went wrong. Try again later.', 'info', 'primary']);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackStateCopy = [...snackState];
    snackStateCopy[0] = false;
    setSnackState(snackStateCopy);
  };

  // Confirmation modal.
  const [revertBoxId, setRevertBoxId] = useState(null);
  const [revertBoxEmail, setRevertBoxEmail] = useState(null);
  const [revertBoxContent, setRevertBoxContent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (revertBoxIdTemp, revertBoxEmailTemp, revertBoxContentTemp) => {
    setRevertBoxId(revertBoxIdTemp);
    setRevertBoxEmail(revertBoxEmailTemp);
    setRevertBoxContent(revertBoxContentTemp);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setRevertBoxId(null);
    setRevertBoxEmail(null);
    setRevertBoxContent(null);
    setModalOpen(false);
  };

  // Box history pagination.
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Tuple in the form [boxIndex, boxId].
  // Used for determining which mapped box was edited/interacted with.
  const [reviewedBoxTuple, setReviewedBoxTuple] = useState([false, false]);

  // Update local snapshot array of values when mark reviewed.
  useEffect(() => {
    if (reviewedBoxTuple[0] !== false && reviewedBoxTuple[1] !== false) {
      getUpdatedHistoryDoc(reviewedBoxTuple[1]).then((updatedDocSnap) => {
        const tempDocSnaps = [...docSnaps];
        tempDocSnaps[reviewedBoxTuple[0]] = updatedDocSnap;
        setDocSnaps(tempDocSnaps);
      });
    }
  }, [reviewedBoxTuple]);

  const handleChangePage = (event, newPage) => {
    if (docSnaps.length < boxCount && (newPage + 1) * rowsPerPage <= docSnaps.length) {
      setLoading(true);
      getBoxHistory(lastVisible).then((resolvedDocSnaps) => {
        setLastVisible(resolvedDocSnaps.docs[resolvedDocSnaps.docs.length - 1]);
        setDocSnaps((oldDocSnaps) => [...oldDocSnaps, ...resolvedDocSnaps.docs]);
        setLoading(false);
      });
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="History">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="top" justifyContent="space-between">
          <Typography variant="h4" sx={{ mb: 4, paddingY: '8px' }}>
            View box history
          </Typography>

          {!loading && (
            <TablePagination
              component="div"
              count={boxCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Stack>
        {loading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={3}>
            {docSnaps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((doc, index) => (
              <Grid item key={doc.id} xs={12} md={12} lg={12}>
                <BoxHistoryCard
                  docIdChild={doc.id}
                  docDataChild={doc.data()}
                  setImagePopUpChild={setImagePopUp}
                  setShowPopUpChild={setShowPopUp}
                  handleModalOpenChild={handleModalOpen}
                  handleModalCloseChild={handleModalClose}
                  modalOpenChild={modalOpen}
                  boxIndex={page * rowsPerPage + index}
                  setReviewedBoxTupleChild={setReviewedBoxTuple}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Snackbar open={snackState[0]} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={snackState[2]}
            sx={{ width: '100%' }}
            color={snackState[3]}
          >
            {snackState[1]}
          </Alert>
        </Snackbar>
      </Container>
      {showPopUp && (
        <Card sx={{ width: 360, height: 360, position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
          <IconButton
            onClick={handleClosePopUp}
            sx={{
              background: '#fff',
              color: '#7776bc',
              zIndex: 10000,
              position: 'absolute',
              top: 8,
              right: 8,
              '&:hover': { background: '#7776bc', color: '#fff' },
            }}
          >
            <CloseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
          <CardMedia
            component="img"
            sx={{
              width: 360,
              position: 'absolute',
              m: 'auto',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '8px',
            }}
            image={imagePopUp}
            alt="Popup product image"
          />
        </Card>
      )}
      <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ px: 6, py: 3, borderBottom: '1px solid rgba(241, 243, 244, 1)' }}>
          Revert this box?
        </DialogTitle>
        <DialogContent sx={{ px: 6, py: '24px !important' }}>
          <DialogContentText sx={{ p: 0, m: 0 }}>
            Reverting this box will remove its contents from the user's inventory and delete its history.
            <strong> This action cannot be undone.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 3, borderTop: '1px solid rgba(241, 243, 244, 1)' }}>
          <Button color="secondary" variant="outlined" onClick={handleModalClose} autoFocus>
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              if (revertBoxId !== null) {
                revertBox(revertBoxId, revertBoxEmail, revertBoxContent);
                setDocSnaps((previousDocSnaps) => previousDocSnaps.filter((doc) => doc.id !== revertBoxId));
                setBoxCount((previousBoxCount) => previousBoxCount - 1);

                const snackStateCopy = [...snackState];
                snackStateCopy[0] = true;
                snackStateCopy[1] = `Reverted box successfully.`;
                snackStateCopy[2] = 'success';
                snackStateCopy[3] = 'primary';
                setSnackState(snackStateCopy);
              } else {
                const snackStateCopy = [...snackState];
                snackStateCopy[0] = true;
                snackStateCopy[1] = `Something went wrong. Please contact Hugo for support.`;
                snackStateCopy[2] = 'error';
                snackStateCopy[3] = 'error';
                setSnackState(snackStateCopy);
              }
              handleModalClose();
            }}
          >
            Revert
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
