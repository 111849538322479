// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  increment,
  query,
  orderBy,
  startAfter,
  limit,
  where,
  arrayRemove,
  serverTimestamp,
  deleteDoc,
  getCountFromServer,
  startAt,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
// Third-party modules (jsPDF, autoTable) for packing slip generation.
import { jsPDF as JSPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { amiHoorayImgData, textLogoImgData } from '../img/data';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const intranetFirebaseConfig = {
  apiKey: 'AIzaSyDbmDSF6zxgKjE_Qm9AXTV5mBjKLlss1d4',
  authDomain: 'anime-box-club-intranet.firebaseapp.com',
  projectId: 'anime-box-club-intranet',
  storageBucket: 'anime-box-club-intranet.appspot.com',
  messagingSenderId: '1015983083728',
  appId: '1:1015983083728:web:14e2809d41fc46883f4ed5',
  measurementId: 'G-J6DFNFKD9Q',
};

// Secondary Firebase project config.
const publicFirebaseConfig = {
  apiKey: 'AIzaSyBAFSEhxLJh_JXaXHWg60FHHDxpV1nPv6I',
  authDomain: 'anime-box-club-official.firebaseapp.com',
  databaseURL: 'https://anime-box-club-official.firebaseio.com',
  projectId: 'anime-box-club-official',
  storageBucket: 'anime-box-club-official.appspot.com',
  messagingSenderId: '657644224166',
  appId: '1:657644224166:web:456b5c28f22386bef8fe67',
  measurementId: 'G-S9C4595QX0',
};

// Initialize Firebase for intranet.
const intranetApp = initializeApp(intranetFirebaseConfig);
export const idb = getFirestore(intranetApp);
export const iauth = getAuth(intranetApp);
export const istorage = getStorage(intranetApp);
export const ifunctions = getFunctions(intranetApp);

// Initialize Firebase for public.
const publicApp = initializeApp(publicFirebaseConfig, 'secondary');
export const pdb = getFirestore(publicApp);
export const pauth = getAuth(publicApp);

// /////////////////////////////// //
//  CLOUD FUNCTIONS DEFINED BELOW  //
// /////////////////////////////// //

export const retrieveCustomerObj = httpsCallable(ifunctions, 'retrievecustomerobj');
export const retrieveCustomerSpend = httpsCallable(ifunctions, 'retrievecustomerspend');
export const retrieveLastCharge = httpsCallable(ifunctions, 'retrievelastcharge');

// /////////////////////////////// //
//  LOCAL FUNCTIONS DEFINED BELOW  //
// /////////////////////////////// //

// Set whitelisted user data.
export async function setAuthUser(uid) {
  const authUserDocRef = doc(idb, 'whitelist', uid);
  const authUserSnapshot = await getDoc(authUserDocRef);

  if (authUserSnapshot.exists()) {
    return authUserSnapshot.data();
  }
  throw Error(`Unauthorized user (${uid}).`);
}

// Get a list of inventory options from intranet database.
export async function createInventoryList(idb) {
  const inventoryCol = collection(idb, 'inventory');
  const inventorySnapshot = await getDocs(inventoryCol);
  const inventoryList = inventorySnapshot.docs.map((doc) => {
    return { ...doc.data(), sku: doc.id };
  });
  return inventoryList;
}

// Get customer information on search.
export async function getCustomerInfo(idb) {
  const userCol = collection(idb, 'users');
}

// Get received items for customers.
export async function getReceivedItems(idb, email) {
  const userDocRef = doc(idb, 'users', email);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    return userSnapshot.data();
  }
  throw Error(`Received items for user (${email}) not found.`);
}

// Get info for searched user for public database.
export async function getSearchUserInfo(pdb, email) {
  // Query user by email value. Should only return a single document.
  const userQuery = query(collection(pdb, 'users'), where('email', '==', email));
  const userQuerySnapshot = await getDocs(userQuery);
  const userList = userQuerySnapshot.docs.map((doc) => {
    return { ...doc.data(), uid: doc.id };
  });

  if (userList.length) {
    return userList;
  }
  throw Error(`User (${email}) not found.`);
}

// Create empty intranet profile for newly subscribed users
// to begin packing process.
export async function createIntranetNewUserDoc(idb, email) {
  await setDoc(doc(idb, 'users', email), {
    figures: [],
    manga: [],
    misc: [],
    snacks: [],
    notes: '',
    lastBoxContents: [],
    lastBoxDate: 'N/A',
    lastBoxNotes: '',
  });
}

// Record new box items for user and update inventory.
export async function addNewBox(
  idb,
  email,
  contents,
  packerEmail,
  customerEmail,
  customerName,
  customerPlan,
  customerPreferences,
  customerAddon,
  customerSize
) {
  // Filter content by first SKU identifier portion.
  const parsedFigures = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'figure';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedManga = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'manga';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedMisc = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'misc';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedSnacks = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'snack';
    })
    .map((el) => {
      return el.sku;
    });

  // Filter contents of last box into all-purpose array
  // for packing slip generation and display.
  const parsedLastBoxArray = contents.map((el) => {
    return { sku: el.sku, description: el.description, categories: el.categories };
  });

  // Calculate date in MM/YYYY.
  const datePacked = new Date();
  let MM = datePacked.getMonth() + 1;
  const YYYY = datePacked.getFullYear();
  if (MM < 10) MM = `0${MM}`;

  // Add contents to box history.
  const boxHistoryRef = await addDoc(collection(idb, 'history'), {
    packer: packerEmail,
    contents: parsedLastBoxArray,
    timestamp: serverTimestamp(),
    reviewed: false,
    email: customerEmail,
    name: customerName,
    plan: customerPlan,
    preferences: customerPreferences,
    addon: customerAddon,
    size: customerSize,
  });

  // Update last box content for public website account and all received items.
  await updateDoc(doc(idb, 'users', email), {
    figures: arrayUnion(...parsedFigures),
    manga: arrayUnion(...parsedManga),
    misc: arrayUnion(...parsedMisc),
    snacks: arrayUnion(...parsedSnacks),
    lastBoxHistoryId: boxHistoryRef.id,
    lastBoxContents: parsedLastBoxArray,
    lastBoxDate: `${MM}/${YYYY}`,
    lastBoxNotes: '', // Reset packing slip notes to prevent redundant messages to customers from prior boxes.
  });

  // Decrement inventory quantities.
  contents.forEach((el) => {
    updateDoc(doc(idb, 'inventory', el.sku), {
      quantity: increment(-1),
    });
  });
}

export async function revertBox(historyId, email, contents) {
  const userDocSnap = await getDoc(doc(idb, 'users', email));
  const userData = userDocSnap.data();

  // Get ID of last box for given user.
  // Check if the ID of the target box to remove is the last box added.
  const lastBoxId = userData.lastBoxHistoryId;
  if (historyId === lastBoxId) {
    return undoAddNewBox(idb, email, contents);
  }

  // Otherwise, leave the last box fields intact and just remove content from inventory.
  // Filter content by first SKU identifier portion.
  const parsedFigures = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'figure';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedManga = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'manga';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedMisc = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'misc';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedSnacks = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'snack';
    })
    .map((el) => {
      return el.sku;
    });

  // Update last box content for public website account and all received items.
  await updateDoc(doc(idb, 'users', email), {
    figures: arrayRemove(...parsedFigures),
    manga: arrayRemove(...parsedManga),
    misc: arrayRemove(...parsedMisc),
    snacks: arrayRemove(...parsedSnacks),
  });

  // Increment inventory quantities.
  contents.forEach((el) => {
    updateDoc(doc(idb, 'inventory', el.sku), {
      quantity: increment(1),
    });
  });

  await deleteDoc(doc(idb, 'history', historyId));
}

export async function undoAddNewBox(idb, email, contents) {
  // Filter content by first SKU identifier portion.
  const parsedFigures = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'figure';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedManga = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'manga';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedMisc = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'misc';
    })
    .map((el) => {
      return el.sku;
    });

  const parsedSnacks = contents
    .filter((el) => {
      return el.sku.split('-')[0] === 'snack';
    })
    .map((el) => {
      return el.sku;
    });

  // Get last box ID and remove from history if undone.
  let lastBoxId = '';
  const docSnap = await getDoc(doc(idb, 'users', email));
  if (docSnap.exists()) {
    lastBoxId = docSnap.data().lastBoxHistoryId;
  }

  // Update last box content for public website account and all received items.
  await updateDoc(doc(idb, 'users', email), {
    figures: arrayRemove(...parsedFigures),
    manga: arrayRemove(...parsedManga),
    misc: arrayRemove(...parsedMisc),
    snacks: arrayRemove(...parsedSnacks),
    lastBoxHistoryId: '',
    lastBoxContents: [],
    lastBoxDate: 'N/A',
    lastBoxNotes: '', // Reset packing slip notes to prevent redundant messages to customers from prior boxes.
  });

  // Increment inventory quantities.
  contents.forEach((el) => {
    updateDoc(doc(idb, 'inventory', el.sku), {
      quantity: increment(1),
    });
  });

  // Remove last box document from box history if it exists.
  if (lastBoxId !== '') {
    await deleteDoc(doc(idb, 'history', lastBoxId));
  }
}

export async function updateNotes(idb, email, notes) {
  await updateDoc(doc(idb, 'users', email), {
    notes,
  });
}

export async function updateLastBoxNotes(idb, email, lastBoxNotes) {
  await updateDoc(doc(idb, 'users', email), {
    lastBoxNotes,
  });
}

export async function addProductToInventory(
  genres,
  productType,
  identifier,
  specificKey,
  dateAdded,
  productName,
  quantity,
  imageSrc
) {
  const generatedSKU = `${productType}-${identifier}-${specificKey}-${dateAdded}`;
  const generatedStorageRef = storageRef(istorage, `inventory/${generatedSKU}.jpg`);

  await setDoc(doc(idb, 'inventory', generatedSKU), {
    categories: genres.join(', '),
    description: productName,
    quantity: parseInt(quantity, 10),
    imageRef: `inventory/${generatedSKU}.jpg`,
  });

  await uploadString(generatedStorageRef, imageSrc, 'data_url');
}

export async function updateProduct(idb, sku, property, value) {
  if (property === 'categories') {
    value = value.join(', ');
  }
  await updateDoc(doc(idb, 'inventory', sku), {
    [property]: value,
  });
}

// Helper function for `generatePackingSlip`.
function processPackingSlipFields(name, plan, addon, contents) {
  const processedName = name.split(' ')[0];
  const processedPlan = plan === 'manga' ? 'Manga Lover' : plan.charAt(0).toUpperCase() + plan.slice(1);
  const processedAddon = addon ? 'Yes' : 'No';
  const processedContents = contents.map((el) => {
    return [el.description, el.categories];
  });
  return [processedName, processedPlan, processedAddon, processedContents];
}

export async function generatePackingSlip(name, plan, addon, shirt, preferences, contents, notes) {
  // Process inputs to make it user-readable.
  const [processedName, processedPlan, processedAddon, processedContents] = processPackingSlipFields(
    name,
    plan,
    addon,
    contents
  );

  // Generate packing slip.
  const doc = new JSPDF({
    orientation: 'portrait',
    format: [101.6, 152.4],
  });

  doc.setFontSize(6);
  doc.setFont('helvetica', 'italic', 'normal');
  const introTitle = doc.splitTextToSize(
    `Hey ${processedName}, we hope you love your personalized Anime loot box!`,
    90
  );
  doc.text(introTitle, 5, 26.5, null, null, 'left');

  doc.setFontSize(5);
  doc.setFont('helvetica', 'italic', 'bold');
  doc.text(
    'Questions or concerns? Email us at support@animeboxclub.com or check out our FAQ page on our website.',
    51.3,
    147.4,
    null,
    null,
    'center'
  );

  // Create user info table.
  autoTable(doc, {
    showHead: 'never',
    body: [
      ['Box Type:', processedPlan],
      ['Add-on Figure?', processedAddon],
      ['Shirt Size:', shirt],
      ['Preferences:', preferences],
    ],
    theme: 'plain',
    startY: 30,
    bodyStyles: { cellPadding: 0.25, fontSize: 6 },
    columnStyles: {
      0: { halign: 'left', fontStyle: 'bold', cellWidth: 20 },
      1: { halign: 'left' },
    },
    margin: { left: 5 },
  });

  // Create box content table.
  autoTable(doc, {
    head: [['Description', 'Genre(s)']],
    body: processedContents,
    theme: 'plain',
    startY: 44,
    bodyStyles: { cellPadding: 1.5, fontSize: 6 },
    headStyles: { cellPadding: 1.5, lineWidth: 0.1, fontSize: 6 },
    tableWidth: doc.internal.pageSize.getWidth() - 10,
    margin: { left: 5 },
    willDrawCell: (data) => {
      if (data.row.section === 'body') {
        doc.setLineWidth(0.1);
        doc.setLineDash([0.5]);
        const rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          doc.setLineDash([0]);
        }
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }
    },
    didDrawPage: (data) => {
      // Footer text containing specific order notes immediately after table.
      doc.setFont('helvetica', 'normal', 'bold');
      doc.text('Order Notes', 5, 44 + (processedContents.length + 1) * 5.43 + 4);
      doc.setFont('helvetica', 'normal', 'normal');
      doc.text(notes, 5, 44 + (processedContents.length + 1) * 5.43 + 8);
    },
  });

  // Add horizontal rules and position images from encoded byte strings.
  doc.addImage(textLogoImgData, 'JPEG', 16.4, 6.3, 68.8, 8);
  doc.setLineWidth(0.1);
  doc.line(0, 20.6, 101.6, 20.6);

  doc.addImage(amiHoorayImgData, 'JPEG', 20.8, 100.4, 60, 42);
  doc.setLineWidth(0.1);
  doc.line(0, 142.4, 101.6, 142.4);

  // Open in new window and bring up print dialog.
  window.open(doc.output('bloburl'), '_blank').print();
}

// Process inventory list to include image download URLs.
export async function processInventoryList(inventory) {
  const imageInventory = inventory.map((product) => {
    const updatedProduct = { ...product };

    // Split comma-seperated categories string into array to work with custom multiselect in data grid.
    updatedProduct.categories = updatedProduct.categories.split(', ');
    const imageRef = storageRef(istorage, product.imageRef);
    getDownloadURL(imageRef)
      .then((url) => {
        updatedProduct.image = url;
      })
      .catch((error) => {
        // TODO: Replace with local image.
        updatedProduct.image =
          'https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg';
      });
    return updatedProduct;
  });
  return imageInventory;
}
export async function getBoxHistoryCount() {
  const historyCol = collection(idb, 'history');
  const historySnapshot = await getCountFromServer(historyCol);
  return historySnapshot.data().count;
}

export async function getBoxHistory(lastDocSnap = false) {
  let paginatedQuery;
  if (lastDocSnap !== false) {
    // Construct a new query starting after the last queried document.
    paginatedQuery = query(
      collection(idb, 'history'),
      orderBy('timestamp', 'desc'),
      startAfter(lastDocSnap),
      // Query by the hundreds so page size changes are easier to manage (since all page sizes are factors of 100).
      limit(100)
    );
  } else {
    // Query the first page of docs.
    paginatedQuery = query(collection(idb, 'history'), orderBy('timestamp', 'desc'), limit(100));
  }
  const docSnaps = await getDocs(paginatedQuery);

  return docSnaps;
}

export async function markReviewed(boxDocId, status) {
  await updateDoc(doc(idb, 'history', boxDocId), {
    reviewed: status,
  });
}

export async function getUpdatedHistoryDoc(boxDocId) {
  const docSnap = await getDoc(doc(idb, 'history', boxDocId));

  if (docSnap.exists()) {
    return docSnap;
  }
  return null;
}
