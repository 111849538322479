import * as Yup from 'yup';
import * as React from "react";
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { iauth, pauth, setAuthUser } from "../../../firebase/firebase";
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  setIsLoggedInGrandChild: PropTypes.func,
  setAuthUserObjGrandChild: PropTypes.func,
};

export default function LoginForm({ setIsLoggedInGrandChild, setAuthUserObjGrandChild }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  // Snackbar state in the form [state, message, severity, color].
  const [snackState, setSnackState] = React.useState([false, "Something went wrong. Try again later.", "info", "primary"]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackStateCopy = [...snackState];
    snackStateCopy[0] = false;
    setSnackState(snackStateCopy);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Sign into intranet first.
    signInWithEmailAndPassword(iauth, data.email, data.password)
      .then((userCredential) => {
        const iuser = userCredential.user;

        // Sign into public next.
        signInWithEmailAndPassword(pauth, data.email, data.password)
          .then((userCredential) => {
            const puser = userCredential.user;

            const snackStateCopy = [...snackState];
            snackStateCopy[0] = true;
            snackStateCopy[1] = "Success! Signing in..."
            snackStateCopy[2] = "success";
            snackStateCopy[3] = "primary";
            setSnackState(snackStateCopy);
            
            setAuthUser(iuser.uid).then((currentAuthUserObj) => {
              // Set authorized user data for global, top-down access.
              setAuthUserObjGrandChild(currentAuthUserObj);

              // Successfully sign into both intranet and public.
              // Redirect to dashboard/app.
              setIsLoggedInGrandChild(true)
              navigate('/dashboard/app', { replace: true });
            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
  
              const snackStateCopy = [...snackState];
              snackStateCopy[0] = true;
              snackStateCopy[1] = "Something went wrong. Please contact Hugo for support."
              snackStateCopy[2] = "warning";
              snackStateCopy[3] = "warning";
              setSnackState(snackStateCopy);
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            const snackStateCopy = [...snackState];
            snackStateCopy[0] = true;
            snackStateCopy[1] = "Something went wrong. Please contact Hugo for support."
            snackStateCopy[2] = "warning";
            snackStateCopy[3] = "warning";
            setSnackState(snackStateCopy);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        const snackStateCopy = [...snackState];
        snackStateCopy[0] = true;
        snackStateCopy[1] = "Unauthorized access. Sign in attempt logged for investigation.";
        snackStateCopy[2] = "error";
        snackStateCopy[3] = "error";
        setSnackState(snackStateCopy);
      });
  };

  return (
    <>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="Email address"
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
    <Snackbar open={snackState[0]} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose}  variant="filled" severity={snackState[2]} sx={{ width: '100%' }} color={snackState[3]}>
        {snackState[1]}
      </Alert>
    </Snackbar>
    </>
  );
}
