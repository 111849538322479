import PropTypes from 'prop-types';
import * as React from 'react';
// @mui
import { Box, Card, CardHeader, Button, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Toolbar, Typography, Stack, Paper, Tooltip, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PrintIcon from '@mui/icons-material/Print';
import UndoIcon from '@mui/icons-material/Undo';
import Skeleton from '@mui/material/Skeleton';
import { generatePackingSlip } from "../../../firebase/firebase";
// ----------------------------------------------------------------------

AppLastBoxPanel.propTypes = {
    lastBoxContentsChild: PropTypes.array,
    lastBoxDateChild: PropTypes.string,
    lastBoxNotesChild: PropTypes.string,
    loadingChild: PropTypes.bool,
    nameChild: PropTypes.string,
    planChild: PropTypes.string,
    preferencesChild: PropTypes.string,
    shirtChild: PropTypes.string,
    addonChild: PropTypes.bool,
    handleUndoChild: PropTypes.func,
};

export default function AppLastBoxPanel({ lastBoxContentsChild, lastBoxDateChild, lastBoxNotesChild, loadingChild, nameChild, planChild, preferencesChild, shirtChild, addonChild, handleUndoChild, }) {
  // lastBoxContentsChild in format:
  // [
  //   { sku: ..., description: ..., categories: ... },
  //   ...
  // ]
  return (
    <>
    {loadingChild ? (
        <Card sx={{p: 3}}>    
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%"}}>
            <Skeleton variant="rounded" width={'35%'} height={59} sx={{mb: 3}} animation="wave" />
            <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{mb: 3}} animation="wave" />
            <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{mb: 3}} animation="wave" />
            <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{mb: 3}} animation="wave" />
            <Skeleton variant="rounded" width={'20%'} height={33.13} animation="wave" />
            </Paper> 
        </Box>
        </Card>
    ) : (
    <Card>
      <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%"}}>
        <Toolbar
          sx={{
          pl: { sm: 3 },
          pr: { sm: 3 },
          width: "100%",
        }}
        >
          <Typography
            sx={{ flex: "1 1 100%", pt: 3, pb: 3, fontSize: "1.125rem", color: "#212B36" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Last box contents
          </Typography>
          <Tooltip title="Undo last add">
            <IconButton onClick={() => { handleUndoChild(); }}>
              <UndoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print packing slip">
            <IconButton onClick={() => { generatePackingSlip(nameChild, planChild, addonChild, shirtChild, preferencesChild, lastBoxContentsChild, lastBoxNotesChild); }}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <caption>Last packed on {lastBoxDateChild}</caption>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Categories</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {lastBoxContentsChild.map((row) => (
                <TableRow
                key={row.sku}
                >
                <TableCell component="th" scope="row">
                    {row.sku}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.categories}</TableCell>
                </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </Box>
    </Card>
    )}
    </>
  );
}
