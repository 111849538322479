import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, TextField, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { idb, updateNotes } from "../../../firebase/firebase";

// ----------------------------------------------------------------------

AppInventoryNotes.propTypes = {
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  denseChild: PropTypes.bool,
  snackStateChild: PropTypes.array,
  setSnackStateChild: PropTypes.func,
  loadingChild: PropTypes.bool,
};

export default function AppInventoryNotes({ label, notes, setNotes, name, email, denseChild, snackStateChild, setSnackStateChild, loadingChild, ...other}) {
  return (
    <Card {...other} sx={{ p: 3, height: "100%" }}>
      {loadingChild ? (
        <>
        <Skeleton variant="rounded" width={'100%'} height={48.75} sx={{ mb: 3 }} animation="wave" />
        <Skeleton variant="rounded" width={"100%"} height={354.13} sx={{mb: 1.5}} animation="wave" />
        <Skeleton variant="rounded" width={'75%'} height={16.25} animation="wave" />
        </>
      ) : (
      <Stack
        direction="column"
        spacing={3}
        justifyContent="space-between"
      >
        <CardHeader sx={{ p: 0 }} title="Additional packing notes" />
          <TextField
            sx={{h: "100% !important"}}
            id="outlined-multiline-static"
            label={`Notes for ${name}`}
            multiline
            fullWidth
            rows={denseChild ? 12 : 15} // 12 for dense padding, 15 for regular
            value={notes || ""}
            onChange={(event) => {
              setNotes(event.target.value);
              updateNotes(idb, email, event.target.value)
            }} 
            helperText="Notes are automatically saved."
            onBlur={(event) => {
              const snackStateCopy = [...snackStateChild];
              snackStateCopy[0] = true;
              snackStateCopy[1] = `Notes successfully saved for "${name}".`;
              snackStateCopy[2] = "success";
              snackStateCopy[3] = "primary";
              setSnackStateChild(snackStateCopy);
            }}
          />
      </Stack>
      )}
    </Card>
  );
}