import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  CardHeader,
  Stack,
  Chip,
  Divider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PrintIcon from '@mui/icons-material/Print';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import { useTheme, styled } from '@mui/material/styles';
// firebase
import { doc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { generatePackingSlip, istorage, markReviewed } from '../../../firebase/firebase';
// helpers
import { formatPlanName, formatChipColor } from '../../../utils/helpers';
// ----------------------------------------------------------------------

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

BoxHistoryCard.propTypes = {
  docIdChild: PropTypes.string,
  docDataChild: PropTypes.object,
  setImagePopUpChild: PropTypes.func,
  setShowPopUpChild: PropTypes.func,
  modalOpenChild: PropTypes.bool,
  handleModalOpenChild: PropTypes.func,
  handleModalCloseChild: PropTypes.func,
  boxIndex: PropTypes.number,
  setReviewedBoxTupleChild: PropTypes.func,
};

export default function BoxHistoryCard({
  docIdChild,
  docDataChild,
  setImagePopUpChild,
  setShowPopUpChild,
  modalOpenChild,
  handleModalOpenChild,
  handleModalCloseChild,
  boxIndex,
  setReviewedBoxTupleChild,
  ...other
}) {
  const theme = useTheme();

  // IconButton dropdown menu.
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Collapsible box content.
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [disabled, setDisabled] = React.useState(false);
  useEffect(() => {
    setDisabled(docDataChild.reviewed);
  }, []);

  return (
    <Card>
      <Box
        sx={{
          width: '100%',
          p: 3,
          opacity: disabled ? 0.25 : 1,
          pointerEvents: disabled ? 'none' : 'initial',
        }}
      >
        <Grid spacing={3} container alignItems="center" direction="row">
          <Grid item md={3}>
            <CardHeader
              sx={{ p: 0 }}
              title={
                <>
                  {new Date(docDataChild.timestamp.seconds * 1000).toLocaleDateString([])}
                  {', '}
                  <Box component="span" sx={{ color: '#ef3675' }}>
                    {new Date(docDataChild.timestamp.seconds * 1000).toLocaleTimeString([], {
                      hour: 'numeric',
                      minute: '2-digit',
                    })}
                  </Box>
                </>
              }
              subheader="Date Packed"
            />
          </Grid>
          <Grid item md={3}>
            <CardHeader
              sx={{ p: 0 }}
              title={
                <>
                  {formatPlanName(docDataChild.plan)}
                  {docDataChild.addon && (
                    <Box component="span" sx={{ display: 'inline-block', color: '#ef3675', fontWeight: 700 }}>
                      {' '}
                      + Figure
                    </Box>
                  )}
                </>
              }
              subheader={docDataChild.preferences}
            />
          </Grid>
          <Grid item md={3}>
            {' '}
            <CardHeader sx={{ p: 0 }} title={docDataChild.name} subheader={docDataChild.email} />
          </Grid>
          <Grid item md={2}>
            <Chip
              label={`${docDataChild.contents.length} item${docDataChild.contents.length > 1 ? 's' : ''}`}
              color={formatChipColor(docDataChild.contents.length)}
            />
          </Grid>
          <Grid item md={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  onClick={() => {
                    generatePackingSlip(
                      docDataChild.name,
                      docDataChild.plan,
                      docDataChild.addon,
                      docDataChild.size,
                      docDataChild.preferences,
                      docDataChild.contents,
                      ''
                    );
                    handleClose();
                  }}
                >
                  Reprint packing slip
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleModalOpenChild(docIdChild, docDataChild.email, docDataChild.contents);
                    handleClose();
                  }}
                  sx={{ color: '#ef3675' }}
                >
                  Revert box
                </MenuItem>
              </Menu>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <Tooltip title="Show contents">
                  <ExpandMoreIcon />
                </Tooltip>
              </ExpandMore>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ px: 3 }}>SKU</TableCell>
              <TableCell sx={{ px: 3 }}>Description</TableCell>
              <TableCell sx={{ px: 3 }}>Categories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docDataChild.contents.map((row) => (
              <TableRow
                hover
                onMouseEnter={(event) =>
                  getDownloadURL(ref(istorage, `inventory/${row.sku}.jpg`))
                    .then((url) => {
                      setImagePopUpChild(url);
                      setShowPopUpChild(true);
                    })
                    .catch((error) => {
                      // Handle any errors
                      setShowPopUpChild(false);
                    })
                }
                onMouseLeave={(event) => setShowPopUpChild(false)}
                key={row.sku}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ px: 3 }}>{row.sku}</TableCell>
                <TableCell sx={{ px: 3 }}>{row.description}</TableCell>
                <TableCell sx={{ px: 3 }}>{row.categories}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
      <Divider sx={{ display: expanded ? 'block' : 'none' }} />
      <Box sx={{ width: '100%', px: 3, py: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={docDataChild.reviewed}
                onChange={(event) => {
                  markReviewed(docIdChild, event.target.checked);
                  setReviewedBoxTupleChild([boxIndex, docIdChild]);
                  setDisabled(event.target.checked);
                  setExpanded(false);
                }}
              />
            }
            label={<Typography variant="caption">Mark reviewed</Typography>}
          />
          <Typography variant="caption">
            Packed by <strong>{docDataChild.packer}</strong>
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
}
