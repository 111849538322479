import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';

// ----------------------------------------------------------------------

AppInventoryGrid.propTypes = {
  rowsChild: PropTypes.array,
  tabStateChild: PropTypes.number,
  tabStateHandlerChild: PropTypes.func,
  loadingChild: PropTypes.bool,
};
  
const columns: GridColDef[] = [
{ field: 'sku', headerName: 'SKU', headerClassName: 'super-app-theme--header', flex: 1 },
{ field: 'description', headerName: 'Description', headerClassName: 'super-app-theme--header', flex: 1 },
{ field: 'categories', headerName: 'Categories', headerClassName: 'super-app-theme--header', flex: 1 },
];

export default function AppInventoryGrid({ rowsChild, tabStateChild, tabStateHandlerChild, loadingChild, ...other}) {
  const [pageSize, setPageSize] = React.useState(10);

  return (
      <>
      {loadingChild ? (
        <Card {...other} sx={{ p: 3 }}>
          <Skeleton variant="rounded" width={'50%'} height={59} sx={{ mb: 3 }} animation="wave" />
          <Skeleton variant="rounded" width={"100%"} height={310} animation="wave" />
        </Card>
      ) : (
        <Card {...other}>
          <Tabs
            value={tabStateChild}
            onChange={tabStateHandlerChild}
            TabIndicatorProps={{ sx: { height: "1px", zIndex: 2000 } }}
          >
            <Tab sx={{ p: 3, fontSize: "1.125rem", color: "#212B36" }} label="Manga" />
            <Tab sx={{ p: 3, fontSize: "1.125rem", color: "#212B36" }} label="Figures" />
            <Tab sx={{ p: 3, fontSize: "1.125rem", color: "#212B36" }} label="Miscellanous" />
            <Tab sx={{ p: 3, fontSize: "1.125rem", color: "#212B36" }} label="Snacks" />
          </Tabs>
          <Box sx={{ height: 370.5, width: '100%', mt: "-1px" }} dir="ltr">
            <DataGrid
              sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, '& .MuiDataGrid-cell': {
                padding: '0 24px',
              },'& .super-app-theme--header': {
                padding: '0 24px',
              },'& .MuiDataGrid-footerContainer': {
                padding: '0 24px',
              },'& .MuiDataGrid-selectedRowCount':{
                margin: '0',
              },}}
              rows={rowsChild}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              getRowId={(row) => row?.sku}
            />
          </Box>
        </Card>
      )}
      </>
  );
}
