export function formatPlanName(plan) {
  switch (plan) {
    case 'signature':
      return 'Signature Box';
    case 'manga':
      return 'Manga Lover Box';
    case 'premium':
      return 'Premium Box';
    case 'regular':
      return 'Regular Box';
    case 'seasonal':
      return 'Seasonal Box';
    case 'onetime':
      return 'One-time Box';
    default:
      return 'Generic Box';
  }
}

export function formatChipColor(count) {
  if (count < 4) return 'error';
  if (count < 6) return 'warning';
  return 'success';
}
