import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Construction() {
  return (
    <Page title="Coming soon">
      <Container maxWidth="sm" sx={{ py: '30vh' }}>
        <Typography variant="h2" align="center" color="text.primary" gutterBottom>
            Coming soon
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph>
            This page is currently under construction. Please check back soon.
        </Typography>
      </Container>
  </Page>
  );
}
