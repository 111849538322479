import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { visuallyHidden } from '@mui/utils';
import { Card, Button, Stack } from '@mui/material';
import { minWidth } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { idb, istorage, addNewBox, getReceivedItems, createInventoryList } from '../../../firebase/firebase';

// ----------------------------------------------------------------------

AppInventoryContents.propTypes = {
  denseChild: PropTypes.bool,
  setDenseChild: PropTypes.func,
  rows: PropTypes.array,
  setContentsChild: PropTypes.func,
  loadingChild: PropTypes.bool,
  emailChild: PropTypes.string,
  setReceivedMangaChild: PropTypes.func,
  setReceivedFiguresChild: PropTypes.func,
  setReceivedMiscChild: PropTypes.func,
  setReceivedSnacksChild: PropTypes.func,
  setTabStateChild: PropTypes.func,
  setReceivedChild: PropTypes.func,
  setInventoryCatalogChild: PropTypes.func,
  inventoryCatalogChild: PropTypes.array,
  receivedMangaChild: PropTypes.array,
  receivedFiguresChild: PropTypes.array,
  receivedMiscChild: PropTypes.array,
  receivedSnacksChild: PropTypes.array,
  setSnackStateChild: PropTypes.func,
  snackStateChild: PropTypes.array,
  setLastBoxContentsChild: PropTypes.func,
  setLastBoxDateChild: PropTypes.func,
  setLastBoxNotesChild: PropTypes.func,
  setImagePopUpChild: PropTypes.func,
  setShowPopUpChild: PropTypes.func,
  authUserObjGrandChild: PropTypes.object,
  nameChild: PropTypes.string,
  planChild: PropTypes.string,
  addonChild: PropTypes.bool,
  preferencesChild: PropTypes.string,
  shirtChild: PropTypes.string,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'sku',
    numeric: false,
    disablePadding: true,
    label: 'SKU',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Categories',
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Quantity',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, denseTableHead } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={denseTableHead ? { p: '0 12px 0 24px !important' } : { p: '0 0 0 15px' }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all items',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              ...(headCell.numeric &&
                denseTableHead && {
                  p: '0 60px 0 0 !important',
                }),
              ...(headCell.numeric &&
                !denseTableHead && {
                  p: '0 57px 0 0 !important',
                }),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  denseTableHead: PropTypes.bool.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, deleteFunc, handleClickConfirmBoxOpenChild } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 3 },
        pr: { sm: 3 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', pt: '26px', pb: '26px' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', pt: 3, pb: 3, fontSize: '1.125rem', color: '#212B36' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          New box contents
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={deleteFunc}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Stack direction="row" spacing={1}>
          {/* <Tooltip title="Generate new box">
              <IconButton>
                <FiberNewIcon />
              </IconButton>
            </Tooltip> */}
          {/* <Tooltip title="Add miscellanous item">
              <IconButton>
                <PlusOneIcon />
              </IconButton>
            </Tooltip> */}
          <Tooltip title="Add box">
            <IconButton onClick={handleClickConfirmBoxOpenChild}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteFunc: PropTypes.func,
  handleClickConfirmBoxOpenChild: PropTypes.func,
};

export default function AppInventoryContents({
  denseChild,
  setDenseChild,
  rows,
  setContentsChild,
  loadingChild,
  emailChild,
  setReceivedMangaChild,
  setReceivedFiguresChild,
  setReceivedMiscChild,
  setReceivedSnacksChild,
  setTabStateChild,
  setReceivedChild,
  setInventoryCatalogChild,
  inventoryCatalogChild,
  receivedMangaChild,
  receivedFiguresChild,
  receivedMiscChild,
  receivedSnacksChild,
  setSnackStateChild,
  snackStateChild,
  setLastBoxContentsChild,
  setLastBoxDateChild,
  setLastBoxNotesChild,
  setImagePopUpChild,
  setShowPopUpChild,
  authUserObjGrandChild,
  nameChild,
  addonChild,
  planChild,
  preferencesChild,
  shirtChild,
  ...other
}) {
  // Confirmation dialog.
  const [confirmBoxOpen, setConfirmBoxOpen] = React.useState(false);
  const handleClickConfirmBoxOpen = () => {
    if (rows && rows.length) {
      setConfirmBoxOpen(true);
    } else {
      const snackStateChildCopy = [...snackStateChild];
      snackStateChildCopy[0] = true;
      snackStateChildCopy[1] = 'Please select items to add to the box first.';
      snackStateChildCopy[2] = 'error';
      snackStateChildCopy[3] = 'error';
      setSnackStateChild(snackStateChildCopy);
    }
  };
  const handleConfirmBoxClose = () => {
    setConfirmBoxOpen(false);
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('quantity');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.sku);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, sku) => {
    const selectedIndex = selected.indexOf(sku);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, sku);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDenseChild(event.target.checked);
  };

  const isSelected = (sku) => selected.indexOf(sku) !== -1;

  // Delete rows on Icon Button click.
  const handleDelete = (event) => {
    let updatedRows = rows;
    selected.forEach((selectedSKU) => {
      updatedRows = updatedRows.filter((product) => product.sku !== selectedSKU);
    });
    setContentsChild(updatedRows);
    setSelected([]);
  };

  // Add new box to Firebase.
  const handleAdd = (event) => {
    if (rows && rows.length) {
      addNewBox(
        idb,
        emailChild,
        rows,
        authUserObjGrandChild.email,
        emailChild,
        nameChild,
        planChild,
        preferencesChild,
        addonChild,
        shirtChild
      );

      // Update inventory catalog item quantity.
      const inventoryCatalogChildTemp = [...inventoryCatalogChild];
      rows.forEach((row) => {
        // Get item whose quantity needs to be updated in inventory catalog.
        const itemIndex = inventoryCatalogChildTemp.findIndex((el) => el.sku === row.sku);
        const itemToUpdate = inventoryCatalogChildTemp.find((el) => el.sku === row.sku);
        itemToUpdate.quantity -= 1;
        inventoryCatalogChildTemp[itemIndex] = itemToUpdate;
      });
      setInventoryCatalogChild(inventoryCatalogChildTemp);

      // Filter content by first SKU identifier portion.
      const updatedFigures = rows
        .filter((el) => {
          return el.sku.split('-')[0] === 'figure';
        })
        .map((obj) => {
          return inventoryCatalogChild.find((item) => item.sku === obj.sku);
        });

      const updatedManga = rows
        .filter((el) => {
          return el.sku.split('-')[0] === 'manga';
        })
        .map((obj) => {
          return inventoryCatalogChild.find((item) => item.sku === obj.sku);
        });

      const updatedMisc = rows
        .filter((el) => {
          return el.sku.split('-')[0] === 'misc';
        })
        .map((obj) => {
          return inventoryCatalogChild.find((item) => item.sku === obj.sku);
        });

      const updatedSnacks = rows
        .filter((el) => {
          return el.sku.split('-')[0] === 'snack';
        })
        .map((obj) => {
          return inventoryCatalogChild.find((item) => item.sku === obj.sku);
        });

      const updatedLastBox = rows.map((obj) => {
        return inventoryCatalogChild.find((item) => item.sku === obj.sku);
      });

      // Calculate date in MM/YYYY.
      const datePacked = new Date();
      let MM = datePacked.getMonth() + 1;
      const YYYY = datePacked.getFullYear();
      if (MM < 10) MM = `0${MM}`;

      // Set new states for last box content (update locally).
      // Firebase update is handled in firebase.js.
      setLastBoxContentsChild(updatedLastBox);
      setLastBoxDateChild(`${MM}/${YYYY}`);
      setLastBoxNotesChild('');

      // Set new states from user values.
      setReceivedMangaChild(receivedMangaChild.concat(updatedManga));
      setReceivedFiguresChild(receivedFiguresChild.concat(updatedFigures));
      setReceivedMiscChild(receivedMiscChild.concat(updatedMisc));
      setReceivedSnacksChild(receivedSnacksChild.concat(updatedSnacks));

      // Reset tab bar.
      setTabStateChild(0);
      setReceivedChild(receivedMangaChild.concat(updatedManga));

      // Reset rows.
      setContentsChild([]);

      // Close confirmation dialog.
      setConfirmBoxOpen(false);

      const snackStateChildCopy = [...snackStateChild];
      snackStateChildCopy[0] = true;
      snackStateChildCopy[1] = `New box successfully added for "${emailChild}"!`;
      snackStateChildCopy[2] = 'success';
      snackStateChildCopy[3] = 'primary';
      setSnackStateChild(snackStateChildCopy);
    } else {
      const snackStateChildCopy = [...snackStateChild];
      snackStateChildCopy[0] = true;
      snackStateChildCopy[1] = 'Please select items to add to the box first.';
      snackStateChildCopy[2] = 'error';
      snackStateChildCopy[3] = 'error';
      setSnackStateChild(snackStateChildCopy);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rows.length - rows.length) : 0;

  return (
    <>
      {loadingChild ? (
        <Card sx={{ p: 3 }}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <Skeleton variant="rounded" width={'35%'} height={59} sx={{ mb: 3 }} animation="wave" />
              <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{ mb: 3 }} animation="wave" />
              <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{ mb: 3 }} animation="wave" />
              <Skeleton variant="rounded" width={'100%'} height={33.13} sx={{ mb: 3 }} animation="wave" />
              <Skeleton variant="rounded" width={'15%'} height={59} animation="wave" />
            </Paper>
          </Box>
        </Card>
      ) : (
        <>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%' }}>
                <EnhancedTableToolbar
                  numSelected={selected.length}
                  deleteFunc={handleDelete}
                  handleClickConfirmBoxOpenChild={handleClickConfirmBoxOpen}
                />
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={denseChild ? 'small' : 'medium'}>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      denseTableHead={denseChild}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rows.length, page * rows.length + rows.length)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.sku);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.sku)}
                              onMouseEnter={(event) =>
                                getDownloadURL(ref(istorage, `inventory/${row.sku}.jpg`))
                                  .then((url) => {
                                    setImagePopUpChild(url);
                                    setShowPopUpChild(true);
                                  })
                                  .catch((error) => {
                                    // Handle any errors
                                    setShowPopUpChild(false);
                                  })
                              }
                              onMouseLeave={(event) => setShowPopUpChild(false)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.sku}
                              selected={isItemSelected}
                            >
                              <TableCell
                                padding="checkbox"
                                sx={denseChild ? { p: '0 9px 0 24px !important' } : { p: '0 0 0 15px !important' }}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {row.sku}
                              </TableCell>
                              <TableCell align="left">{row.description}</TableCell>
                              <TableCell align="left">{row.categories}</TableCell>
                              <TableCell
                                align="right"
                                sx={denseChild ? { p: '0 60px 0 0 !important' } : { p: '0 57px 0 0 !important' }}
                              >
                                {row.quantity}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (denseChild ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={4} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <FormControlLabel
                  control={<Switch checked={denseChild} onChange={handleChangeDense} />}
                  label="Dense padding"
                  sx={{ m: 1.5 }}
                />
              </Paper>
            </Box>
          </Card>
          <Dialog
            open={confirmBoxOpen}
            onClose={handleConfirmBoxClose}
            aria-labelledby="alert-new-box-confirmation"
            aria-describedby="alert-new-box-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle
              id="alert-new-box-confirmation"
              sx={{ px: 6, py: 3, borderBottom: '1px solid rgba(241, 243, 244, 1)' }}
            >
              Add these{' '}
              <Box component="span" sx={{ display: 'inline-block', color: '#7776bc' }}>
                {rows.length}
              </Box>{' '}
              items?
            </DialogTitle>
            <Box sx={{ px: 6, py: 3 }}>
              {rows.filter((el) => {
                return el.sku.split('-')[0] === 'manga';
              }).length > 0 && (
                <List
                  dense
                  subheader={
                    <ListSubheader sx={{ p: 0, color: '#7776bc' }} component="div" id="nested-list-subheader">
                      Manga
                    </ListSubheader>
                  }
                  sx={{ p: 0 }}
                >
                  {rows
                    .filter((el) => {
                      return el.sku.split('-')[0] === 'manga';
                    })
                    .map((obj) => (
                      <ListItem sx={{ pl: 3, py: 0, pr: 0 }}>
                        <ListItemText primary={obj.description} />
                      </ListItem>
                    ))}
                </List>
              )}
              {rows.filter((el) => {
                return el.sku.split('-')[0] === 'figure';
              }).length > 0 && (
                <List
                  dense
                  subheader={
                    <ListSubheader sx={{ p: 0, color: '#7776bc' }} component="div" id="nested-list-subheader">
                      Figures
                    </ListSubheader>
                  }
                  sx={{ p: 0 }}
                >
                  {rows
                    .filter((el) => {
                      return el.sku.split('-')[0] === 'figure';
                    })
                    .map((obj) => (
                      <ListItem sx={{ pl: 3, py: 0, pr: 0 }}>
                        <ListItemText primary={obj.description} />
                      </ListItem>
                    ))}
                </List>
              )}
              {rows.filter((el) => {
                return el.sku.split('-')[0] === 'misc';
              }).length > 0 && (
                <List
                  dense
                  subheader={
                    <ListSubheader sx={{ p: 0, color: '#7776bc' }} component="div" id="nested-list-subheader">
                      Miscellanous
                    </ListSubheader>
                  }
                  sx={{ p: 0 }}
                >
                  {rows
                    .filter((el) => {
                      return el.sku.split('-')[0] === 'misc';
                    })
                    .map((obj) => (
                      <ListItem sx={{ pl: 3, py: 0, pr: 0 }}>
                        <ListItemText primary={obj.description} />
                      </ListItem>
                    ))}
                </List>
              )}
              {rows.filter((el) => {
                return el.sku.split('-')[0] === 'snack';
              }).length > 0 && (
                <List
                  dense
                  subheader={
                    <ListSubheader sx={{ p: 0, color: '#7776bc' }} component="div" id="nested-list-subheader">
                      Snacks
                    </ListSubheader>
                  }
                  sx={{ p: 0 }}
                >
                  {rows
                    .filter((el) => {
                      return el.sku.split('-')[0] === 'snack';
                    })
                    .map((obj) => (
                      <ListItem sx={{ pl: 3, py: 0, pr: 0 }}>
                        <ListItemText primary={obj.description} />
                      </ListItem>
                    ))}
                </List>
              )}
            </Box>
            <DialogActions sx={{ px: 6, py: 3, borderTop: '1px solid rgba(241, 243, 244, 1)' }}>
              <Button variant="outlined" onClick={handleConfirmBoxClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleAdd} autoFocus>
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
