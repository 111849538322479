import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, IconButton, Card, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppCustomerInfo,
  AppInventoryGrid,
  AppInventorySearch,
  AppInventoryNotes,
  AppInventoryContents,
  // Temp name for now.
  AppLastBoxPanel,
  AppLastBoxNotes,
  AppStripeInfo,
} from '../sections/@dashboard/app';
import {
  idb,
  pdb,
  createInventoryList,
  getReceivedItems,
  getSearchUserInfo,
  createIntranetNewUserDoc,
  undoAddNewBox,
  retrieveCustomerObj,
  retrieveCustomerSpend,
  retrieveLastCharge,
} from '../firebase/firebase';

// ----------------------------------------------------------------------

DashboardApp.propTypes = {
  currentUserChild: PropTypes.string,
  authUserObjChild: PropTypes.object,
};

export default function DashboardApp({ currentUserChild, authUserObjChild }) {
  const theme = useTheme();

  // NOTE: Replace with default values after Firebase implementation.
  // User info states below.
  // Name state.
  const [name, setName] = React.useState('First Last');

  // Plan state.
  const [plan, setPlan] = React.useState('Plan');

  // Email state.
  const [email, setEmail] = React.useState('example@email.com');

  // Join date state.
  const [join, setJoin] = React.useState('MM/DD/YY');

  // Preferences state.
  const [preferences, setPreferences] = React.useState('');

  // Shirt size state.
  const [shirt, setShirt] = React.useState('L');

  // Stripe states.
  const [shippingAddress, setShippingAddress] = React.useState({
    name: '',
    phone: '',
    address: {
      city: '',
      country: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
  });
  const [billingAddress, setBillingAddress] = React.useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
  });
  const [totalSpend, setTotalSpend] = React.useState(0);
  const [paidInvoices, setPaidInvoices] = React.useState(0);
  const [lastInvoiceAmount, setLastInvoiceAmount] = React.useState(0);
  const [lastInvoiceTimestamp, setLastInvoiceTimestamp] = React.useState(0);

  // Received items state.
  const [received, setReceived] = React.useState([]);
  const [receivedManga, setReceivedManga] = React.useState([]);
  const [receivedFigures, setReceivedFigures] = React.useState([]);
  const [receivedMisc, setReceivedMisc] = React.useState([]);
  const [receivedSnacks, setReceivedSnacks] = React.useState([]);

  // Notes.
  const [notes, setNotes] = React.useState('Search for a user first to view their packing notes.');

  // Dense padding state.
  const [dense, setDense] = React.useState(false);

  // New box contents state.
  const [contents, setContents] = React.useState([]);

  // Last box contents state.
  const [lastBoxContents, setLastBoxContents] = React.useState([]);
  const [lastBoxDate, setLastBoxDate] = React.useState('N/A');
  const [lastBoxNotes, setLastBoxNotes] = React.useState('');
  const [addon, setAddon] = React.useState(false);

  // Product image popup URL.
  const [imagePopUp, setImagePopUp] = React.useState('');
  const [showPopUp, setShowPopUp] = React.useState(false);
  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  // Snackbar state in the form [state, message, severity, color].
  const [snackState, setSnackState] = React.useState([
    false,
    'Something went wrong. Try again later.',
    'info',
    'primary',
  ]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackStateCopy = [...snackState];
    snackStateCopy[0] = false;
    setSnackState(snackStateCopy);
  };

  // Inventory grid tabs state.
  const [tabState, setTabState] = React.useState(0);

  // Inventory grid tab handlers
  const handleTabChange = (event, value) => {
    setTabState(value);
    switch (value) {
      case 0:
        setReceived(receivedManga);
        break;
      case 1:
        setReceived(receivedFigures);
        break;
      case 2:
        setReceived(receivedMisc);
        break;
      case 3:
        setReceived(receivedSnacks);
        break;
      default:
        setReceived(receivedManga);
        break;
    }
  };

  // Dashboard loading state.
  const [loading, setLoading] = React.useState(true);

  // Dynamic inventory catalog by SKU.
  const [inventoryCatalog, setInventoryCatalog] = React.useState([]);

  // Equivalent to componentDidMount.
  useEffect(() => {
    // On page load, set skeleton interface and load general data.
    // E.g. Inventory data, data that is shared by all users, etc.
    setLoading(true);
    setReceived([]);
    const list = createInventoryList(idb);
    list.then((resolvedList) => {
      setInventoryCatalog(resolvedList);
    });
  }, []);

  // Equivalent to componentDidUpdate for previous prop dependencies.
  useEffect(() => {
    if (currentUserChild !== '') {
      getSearchUserInfo(pdb, currentUserChild)
        .then((resolvedItems) => {
          setLoading(true);

          // Set states for user values from public.
          setEmail(currentUserChild);
          setName(`${resolvedItems[0].first} ${resolvedItems[0].last}`);
          setPlan(resolvedItems[0].plan);
          setJoin(resolvedItems[0].join);
          setPreferences(resolvedItems[0].pref);
          setShirt(resolvedItems[0].size);
          setAddon(Boolean(resolvedItems[0].addonFigure));

          // Callable cloud function.
          retrieveCustomerObj({ cusId: resolvedItems[0].cus_id })
            .then((result) => {
              // Set retrieved Stripe shipping/billing addresses from callable Cloud function.
              setShippingAddress(result.data.shipping);
              setBillingAddress(result.data.address);
            })
            .catch((error) => {
              // Getting the Error details.
              const code = error.code;
              const message = error.message;
              const details = error.details;
            });

          // Callable cloud function.
          retrieveCustomerSpend({ cusId: resolvedItems[0].cus_id })
            .then((result) => {
              // Set calculated total spend of retrieved paid invoices from callable Cloud function.
              setTotalSpend(result.data.total);
              setPaidInvoices(result.data.count);
            })
            .catch((error) => {
              // Getting the Error details.
              const code = error.code;
              const message = error.message;
              const details = error.details;
            });

          // Callable cloud function.
          retrieveLastCharge({ cusId: resolvedItems[0].cus_id })
            .then((result) => {
              // Set calculated total spend of retrieved paid invoices from callable Cloud function.
              setLastInvoiceAmount(result.data.amount);
              setLastInvoiceTimestamp(result.data.timestamp);
            })
            .catch((error) => {
              // Getting the Error details.
              const code = error.code;
              const message = error.message;
              const details = error.details;
            });

          getReceivedItems(idb, currentUserChild)
            .then((resolvedItems) => {
              // Parse received items from inventory catalog by SKU.
              const manga = resolvedItems.manga.map((receivedSku) => {
                return inventoryCatalog.find((item) => item.sku === receivedSku);
              });
              const figures = resolvedItems.figures.map((receivedSku) => {
                return inventoryCatalog.find((item) => item.sku === receivedSku);
              });
              const misc = resolvedItems.misc.map((receivedSku) => {
                return inventoryCatalog.find((item) => item.sku === receivedSku);
              });
              const snacks = resolvedItems.snacks.map((receivedSku) => {
                return inventoryCatalog.find((item) => item.sku === receivedSku);
              });

              // Set states from user values.
              setReceivedManga(manga);
              setReceivedFigures(figures);
              setReceivedMisc(misc);
              setReceivedSnacks(snacks);
              setNotes(resolvedItems.notes);
              setLastBoxContents(resolvedItems.lastBoxContents);
              setLastBoxDate(resolvedItems.lastBoxDate);
              setLastBoxNotes(resolvedItems.lastBoxNotes);

              // Reset tab bar.
              setTabState(0);
              setReceived(manga);
            })
            .catch((error) => {
              // Create new intranet profile for new users.
              createIntranetNewUserDoc(idb, currentUserChild);
              setReceivedManga([]);
              setReceivedFigures([]);
              setReceivedMisc([]);
              setReceivedSnacks([]);
              setNotes('');
              setLastBoxContents([]);
              setLastBoxDate('N/A');
              setLastBoxNotes('');

              // Reset tab bar.
              setTabState(0);
              setReceived([]);
            });

          // Snackbar notification. Possibly turn this into a callable function.
          const snackStateCopy = [...snackState];
          snackStateCopy[0] = true;
          snackStateCopy[1] = `Loaded customer information for "${currentUserChild}".`;
          snackStateCopy[2] = 'success';
          snackStateCopy[3] = 'primary';
          setSnackState(snackStateCopy);
          setContents([]);
          setLoading(false);
        })
        .catch((error) => {
          const snackStateCopy = [...snackState];
          snackStateCopy[0] = true;
          snackStateCopy[1] = `No user found with email "${currentUserChild}".`;
          snackStateCopy[2] = 'error';
          snackStateCopy[3] = 'error';
          setSnackState(snackStateCopy);
        });
    }
  }, [currentUserChild]);

  // Handle last box undo add.
  const handleUndo = (event) => {
    if (lastBoxContents && lastBoxContents.length) {
      // Get SKUs of last box contents.
      const lastBoxContentsFilter = lastBoxContents.map((obj) => obj.sku);

      // Filter inventory catalog by SKUs (cannot pass lastBoxContents directly as it truncates `quantity`).
      const lastBoxToInventory = inventoryCatalog.filter((item) => lastBoxContentsFilter.includes(item.sku));

      // Update quantity to reverse subtraction from adding.
      lastBoxToInventory.forEach((item) => {
        item.quantity += 1;
      });

      // Update Firebase.
      undoAddNewBox(idb, email, lastBoxToInventory);

      // Filter content by first SKU identifier portion.
      const updatedFigures = lastBoxContents
        .filter((el) => {
          return el.sku.split('-')[0] === 'figure';
        })
        .map((obj) => {
          return inventoryCatalog.find((item) => item.sku === obj.sku);
        });

      const updatedManga = lastBoxContents
        .filter((el) => {
          return el.sku.split('-')[0] === 'manga';
        })
        .map((obj) => {
          return inventoryCatalog.find((item) => item.sku === obj.sku);
        });

      const updatedMisc = lastBoxContents
        .filter((el) => {
          return el.sku.split('-')[0] === 'misc';
        })
        .map((obj) => {
          return inventoryCatalog.find((item) => item.sku === obj.sku);
        });

      const updatedSnacks = lastBoxContents
        .filter((el) => {
          return el.sku.split('-')[0] === 'snack';
        })
        .map((obj) => {
          return inventoryCatalog.find((item) => item.sku === obj.sku);
        });

      // Set new states from user values.
      setReceivedManga(receivedManga.filter((el) => !updatedManga.includes(el)));
      setReceivedFigures(receivedFigures.filter((el) => !updatedFigures.includes(el)));
      setReceivedMisc(receivedMisc.filter((el) => !updatedMisc.includes(el)));
      setReceivedSnacks(receivedSnacks.filter((el) => !updatedSnacks.includes(el)));

      // Reset tab bar.
      setTabState(0);
      setReceived(receivedManga.filter((el) => !updatedManga.includes(el)));

      // Empty last box contents and set to inventory contents.
      setLastBoxContents([]);
      setLastBoxDate('N/A');
      setContents(lastBoxToInventory);

      const snackStateCopy = [...snackState];
      snackStateCopy[0] = true;
      snackStateCopy[1] = `Undid last box for "${email}".`;
      snackStateCopy[2] = 'success';
      snackStateCopy[3] = 'primary';
      setSnackState(snackStateCopy);
    } else {
      const snackStateCopy = [...snackState];
      snackStateCopy[0] = true;
      snackStateCopy[1] = `Unable to undo empty last box.`;
      snackStateCopy[2] = 'error';
      snackStateCopy[3] = 'error';
      setSnackState(snackStateCopy);
    }
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Customer information
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppCustomerInfo
              name={name}
              plan={plan}
              email={email}
              join={`Since ${join}`}
              addon={addon}
              preferences={preferences}
              shirt={shirt}
              loadingChild={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInventoryGrid
              rowsChild={received}
              tabStateChild={tabState}
              tabStateHandlerChild={handleTabChange}
              loadingChild={loading}
            />
          </Grid>
        </Grid>

        <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
          Stripe information
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppStripeInfo
              shippingAddressChild={shippingAddress}
              billingAddressChild={billingAddress}
              totalSpendChild={totalSpend}
              paidInvoicesChild={paidInvoices}
              lastInvoiceAmountChild={lastInvoiceAmount}
              lastInvoiceTimestampChild={lastInvoiceTimestamp}
              loadingChild={loading}
            />
          </Grid>
        </Grid>

        <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
          Add another box
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={9}>
            <AppInventorySearch
              contentsChild={contents}
              setContentsChild={setContents}
              snackStateChild={snackState}
              setSnackStateChild={setSnackState}
              inventoryCatalogChild={inventoryCatalog}
              loadingChild={loading}
              receivedFiguresChild={receivedFigures}
              receivedMangaChild={receivedManga}
              receivedMiscChild={receivedMisc}
              receivedSnacksChild={receivedSnacks}
            />
            <AppInventoryContents
              denseChild={dense}
              setDenseChild={setDense}
              rows={contents}
              setContentsChild={setContents}
              loadingChild={loading}
              emailChild={email}
              setReceivedMangaChild={setReceivedManga}
              setReceivedFiguresChild={setReceivedFigures}
              setReceivedMiscChild={setReceivedMisc}
              setReceivedSnacksChild={setReceivedSnacks}
              setTabStateChild={setTabState}
              setReceivedChild={setReceived}
              setInventoryCatalogChild={setInventoryCatalog}
              inventoryCatalogChild={inventoryCatalog}
              receivedMangaChild={receivedManga}
              receivedFiguresChild={receivedFigures}
              receivedMiscChild={receivedMisc}
              receivedSnacksChild={receivedSnacks}
              setSnackStateChild={setSnackState}
              snackStateChild={snackState}
              setLastBoxContentsChild={setLastBoxContents}
              setLastBoxDateChild={setLastBoxDate}
              setLastBoxNotesChild={setLastBoxNotes}
              setImagePopUpChild={setImagePopUp}
              setShowPopUpChild={setShowPopUp}
              authUserObjGrandChild={authUserObjChild}
              nameChild={name}
              planChild={plan}
              addonChild={addon}
              preferencesChild={preferences}
              shirtChild={shirt}
            />
          </Grid>
          <Grid item xs={3}>
            <AppInventoryNotes
              notes={notes}
              setNotes={setNotes}
              name={name}
              email={email}
              denseChild={dense}
              snackStateChild={snackState}
              setSnackStateChild={setSnackState}
              loadingChild={loading}
            />
          </Grid>
        </Grid>

        <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
          View last box
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={9}>
            <AppLastBoxPanel
              lastBoxContentsChild={lastBoxContents}
              lastBoxDateChild={lastBoxDate}
              lastBoxNotesChild={lastBoxNotes}
              loadingChild={loading}
              nameChild={name}
              planChild={plan}
              preferencesChild={preferences}
              shirtChild={shirt}
              addonChild={addon}
              handleUndoChild={handleUndo}
            />
          </Grid>
          <Grid item xs={3}>
            <AppLastBoxNotes
              lastBoxNotesChild={lastBoxNotes}
              setLastBoxNotesChild={setLastBoxNotes}
              name={name}
              email={email}
              snackStateChild={snackState}
              setSnackStateChild={setSnackState}
              loadingChild={loading}
            />
          </Grid>
        </Grid>

        <Snackbar open={snackState[0]} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={snackState[2]}
            sx={{ width: '100%' }}
            color={snackState[3]}
          >
            {snackState[1]}
          </Alert>
        </Snackbar>
      </Container>
      {showPopUp && (
        <Card sx={{ width: 360, height: 360, position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
          <IconButton
            onClick={handleClosePopUp}
            sx={{
              background: '#fff',
              color: '#7776bc',
              zIndex: 10000,
              position: 'absolute',
              top: 8,
              right: 8,
              '&:hover': { background: '#7776bc', color: '#fff' },
            }}
          >
            <CloseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
          <CardMedia
            component="img"
            sx={{
              width: 360,
              position: 'absolute',
              m: 'auto',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '8px',
            }}
            image={imagePopUp}
            alt="Popup product image"
          />
        </Card>
      )}
    </Page>
  );
}
