import PropTypes from 'prop-types';
import * as React from 'react';
// @mui
import { OutlinedInput, Select, InputLabel, InputAdornment, IconButton, Divider, Box, Card, CardHeader, CardActions, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Stack, TextField, MenuItem, Input, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, autocompleteClasses } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PrintIcon from '@mui/icons-material/Print';
import Skeleton from '@mui/material/Skeleton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Webcam from "react-webcam";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridRowsProp, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { idb, addProductToInventory, updateProduct } from "../../../firebase/firebase";
// ----------------------------------------------------------------------

InventoryEditGrid.propTypes = {
  setSnackStateChild: PropTypes.func,
  snackStateChild: PropTypes.array,
  rowsChild: PropTypes.array,
  authUserObjGrandChild: PropTypes.object,
};

// Custom multiselect inside data grid from https://github.com/mui/mui-x/issues/4410#issuecomment-1095104938.
// Extended functionality to limit to 3 and match styling.
const genreOptions = [
    'N/A',
    'TRADITIONAL',
    'ACTION',
    'ADVENTURE',
    'SPORTS',
    'ROMANCE',
    'COMEDY',
    'SLICE OF LIFE',
    'ECCHI',
];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function CustomEditComponent(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    const eventValue = event.target.value; // The new value entered by the user
    const newValue =
      typeof eventValue === "string" ? value.split(",") : eventValue;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue.filter((x) => x !== "")
    });
  };


  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      multiple
      value={value}
      onChange={handleChange}
      sx={{ width: "100%", height: "100%", "& fieldset": { border: 'none' }, }}
    >
      {genreOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

const CustomGenresEditCell = (params) => <CustomEditComponent {...params} />;

function CustomFilterInputSingleSelect(props) {
    const { item, applyValue, type, apiRef, focusElementRef, ...others } = props;

    return (
        <TextField
        id={`contains-input-${item.id}`}
        value={item.value}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        type={type || "text"}
        variant="standard"
        InputLabelProps={{
            shrink: true
        }}
        inputRef={focusElementRef}
        select
        SelectProps={{
            native: true
        }}
        >
        {["", ...genreOptions].map((option) => (
            <option key={option} value={option}>
            {option}
            </option>
        ))}
        </TextField>
    );
}
  
export default function InventoryEditGrid({ setSnackStateChild, snackStateChild, rowsChild, authUserObjGrandChild, ...other }) {
    const [pageSize, setPageSize] = React.useState(50);

    const columns: GridColDef[] = [
      { field: 'image', headerName: 'Image', headerClassName: 'super-app-theme--header', flex: 1, renderCell: (params) => <img alt={"Product"} src={params.value}/> },
      { field: 'sku', headerName: 'SKU', headerClassName: 'super-app-theme--header', flex: 1 },
      { field: 'description', headerName: 'Description', headerClassName: 'super-app-theme--header', flex: 1, editable: authUserObjGrandChild.inventoryEdit, },
      {
          field: "categories",
          headerName: 'Categories',
          type: "singleSelect",
          flex: 1,
          editable: authUserObjGrandChild.inventoryEdit,
          valueOptions: genreOptions,
          valueFormatter: ({ value }) => (value ? value.join(", ") : ""),
          renderEditCell: CustomGenresEditCell,
          filterOperators: [
            {
              value: "contains",
              getApplyFilterFn: (filterItem) => {
                if (filterItem.value == null || filterItem.value === "") {
                  return null;
                }
                return ({ value }) => {
                  // if one of the cell values corresponds to the filter item
                  return value.some((cellValue) => cellValue === filterItem.value);
                };
              },
              InputComponent: CustomFilterInputSingleSelect
            }
          ],
      },
      { field: 'quantity', headerName: 'Quantity', headerClassName: 'super-app-theme--header', flex: 1, editable: authUserObjGrandChild.inventoryEdit, type: "number", },
    ];

    return (
        <Card>
            <Box sx={{ width: "100%", p: 3, }}>
                <Stack
                    direction="column"
                    spacing={3}
                    justifyContent="space-between"
                >
                    <CardHeader sx={{ p: 0 }} title="Product catalog" />
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }} dir="ltr">
                <DataGrid
                    sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, '& .MuiDataGrid-cell': {
                        padding: '0 24px',
                    },'& .super-app-theme--header': {
                        padding: '0 24px',
                    },'& .MuiDataGrid-footerContainer': {
                        padding: '0 24px',
                    },'& .MuiDataGrid-selectedRowCount':{
                        margin: '0',
                    },}}
                    rows={rowsChild}
                    getRowHeight={() => 'auto'}
                    autoHeight
                    columns={columns}
                    pageSize={pageSize}
                    onCellEditCommit={(params, event) => {
                        updateProduct(idb, params.id, params.field, params.value);
                        const snackStateCopy = [...snackStateChild];
                        snackStateCopy[0] = true;
                        snackStateCopy[1] = `Updated "${capitalizeFirstLetter(params.field)}" to "${params.value}" for SKU "${params.id}".`;
                        snackStateCopy[2] = "success";
                        snackStateCopy[3] = "primary";
                        setSnackStateChild(snackStateCopy);
                     }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    getRowId={(row) => row?.sku}
                />
            </Box>
        </Card>
    );
}