import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Restricted() {
  return (
    <Page title="Access denied">
      <Container maxWidth="sm" sx={{ py: '30vh' }}>
        <Typography variant="h2" align="center" color="text.primary" gutterBottom>
            Access denied
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph>
            You are unauthorized to view this page. If you believe this is a mistake, please contact your assigned Admin.
        </Typography>
      </Container>
  </Page>
  );
}
