import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Skeleton from '@mui/material/Skeleton';
// ----------------------------------------------------------------------

AppCustomerInfo.propTypes = {
  name: PropTypes.string,
  plan: PropTypes.string,
  email: PropTypes.string,
  join: PropTypes.string,
  preferences: PropTypes.string,
  shirt: PropTypes.string,
  loadingChild: PropTypes.bool,
  addon: PropTypes.bool,
};

export default function AppCustomerInfo({ name, plan, email, join, preferences, shirt, loadingChild, addon, ...other}) {
  return (
    <Card {...other} sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader sx={{ p: 0 }} title={name} subheader={email} />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader sx={{ p: 0, textTransform: "capitalize" }} title={`${(plan === "manga") ? "Manga Lover" : plan}${addon ? " + Add-on Figure" : ""}`} subheader={join} />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader sx={{ p: 0 }} title="Preferences" subheader={preferences} />
          )}
        </Grid>
        <Grid xs={6} md={3}>
          {loadingChild ? (
            <>
              <Skeleton width="60%" animation="wave" />
              <Skeleton width="90%" animation="wave" />
            </>
          ) : (
            <CardHeader sx={{ p: 0 }} title="Shirt size" subheader={shirt} />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
