import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Snackbar, Alert, Box, Stack, Card, Link, Container, Typography, Grid } from '@mui/material';
// firebase
import { idb, createInventoryList, processInventoryList } from '../firebase/firebase';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import { InventorySKUSurvey, InventoryEditGrid } from '../sections/@dashboard/inventory';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

Inventory.propTypes = {
  authUserObjChild: PropTypes.object,
};

export default function Inventory({ authUserObjChild }) {
  const [rows, setRows] = React.useState([]);

  // Snackbar state in the form [state, message, severity, color].
  const [snackState, setSnackState] = React.useState([
    false,
    'Something went wrong. Try again later.',
    'info',
    'primary',
  ]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackStateCopy = [...snackState];
    snackStateCopy[0] = false;
    setSnackState(snackStateCopy);
  };

  // Equivalent to componentDidMount.
  useEffect(() => {
    // On page load, set skeleton interface and load general data.
    // E.g. Inventory data, data that is shared by all users, etc.
    createInventoryList(idb).then((resolvedList) => {
      processInventoryList(resolvedList).then((imageList) => {
        // Timeout hack to prevent rows from loading before images.
        const timer = setTimeout(() => setRows(imageList), 500);
        return () => clearTimeout(timer);
      });
    });
  }, []);

  return (
    <Page title="Inventory">
      <Container maxWidth="xl">
        {authUserObjChild.inventoryAdd && (
          <>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Add a product
            </Typography>

            <Grid container spacing={3} sx={{ mb: 5 }}>
              <Grid item xs={12} md={12} lg={12}>
                <InventorySKUSurvey
                  setSnackStateChild={setSnackState}
                  snackStateChild={snackState}
                  setRowsChild={setRows}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Typography variant="h4" sx={{ mb: 5 }}>
          {authUserObjChild.inventoryEdit ? 'Edit' : 'View'} inventory
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <InventoryEditGrid
              setSnackStateChild={setSnackState}
              snackStateChild={snackState}
              rowsChild={rows}
              authUserObjGrandChild={authUserObjChild}
            />
          </Grid>
        </Grid>

        <Snackbar open={snackState[0]} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={snackState[2]}
            sx={{ width: '100%' }}
            color={snackState[3]}
          >
            {snackState[1]}
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
