import PropTypes from 'prop-types';
import * as React from 'react';
// @mui
import { OutlinedInput, Select, InputLabel, InputAdornment, IconButton, Divider, Box, Card, CardHeader, CardActions, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Stack, TextField, MenuItem, Input, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, autocompleteClasses } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PrintIcon from '@mui/icons-material/Print';
import Skeleton from '@mui/material/Skeleton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Webcam from "react-webcam";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTheme } from '@mui/material/styles';
import { idb, addProductToInventory, createInventoryList, processInventoryList } from "../../../firebase/firebase";
// ----------------------------------------------------------------------

InventorySKUSurvey.propTypes = {
  setSnackStateChild: PropTypes.func,
  snackStateChild: PropTypes.array,
  setRowsChild: PropTypes.func,
};

// Multiselect functionality.
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const genresList = [
  'N/A',
  'TRADITIONAL',
  'ACTION',
  'ADVENTURE',
  'SPORTS',
  'ROMANCE',
  'COMEDY',
  'SLICE OF LIFE',
  'ECCHI',
];

function getStyles(genre, genres, theme) {
  return {
    fontWeight:
      genres.indexOf(genre) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function InventorySKUSurvey({ setSnackStateChild, snackStateChild, setRowsChild, ...other }) {
  const theme = useTheme();
  const [genres, setGenres] = React.useState([]);
  const [genresError, setGenresError] = React.useState(false);

  const handleSelectChange = (event) => {
    setGenresError(false);
    const {
      target: { value },
    } = event;
    setGenres(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function todayDate() {
    const today = new Date();
    const month = today.toLocaleString('en-us', { month: 'short' });
    return `${month.toString().toLowerCase()}.${today.getFullYear().toString().substr(-2)}`;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Webcam functionality.
  const [imageSrc, setImageSrc] = React.useState(false);
  const videoConstraints = {
    width: 360,
    height: 360,
    facingMode: "user"
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      setImageSrc(webcamRef.current.getScreenshot());
    },
    [webcamRef]
  );

  const [productType, setProductType] = React.useState("");
  const [productTypeError, setProductTypeError] = React.useState(false);

  const [identifier, setIdentifier] = React.useState("");
  const [identifierError, setIdentifierError] = React.useState(false);

  const [specificKey, setSpecificKey] = React.useState("");
  const [specificKeyError, setSpecificKeyError] = React.useState(false);

  const [dateAdded, setDateAdded] = React.useState(todayDate());
  const [dateAddedError, setDateAddedError] = React.useState(false);
  const allowedDates = ["jan.", "feb.", "mar.", "apr.", "may.", "jun.", "jul.", "aug.", "sep.", "oct.", "nov.", "dec."];

  const [productName, setProductName] = React.useState("");
  const [productNameError, setProductNameError] = React.useState(false);

  const [quantity, setQuantity] = React.useState(0);
  const [quantityError, setQuantityError] = React.useState(false);

  const handleAddProduct = (event) => {
    const snackStateCopy = [...snackStateChild];

    // Extensive manual error handling.
    if (!genres || !genres.length) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Categories required. Use the drop-down menu to select up to 3 categories.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setGenresError(true);
    }
    else if (genres.length > 3) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Maximum of 3 categories allowed. Use the drop-down menu to select up to 3 categories.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setGenresError(true);
    }
    else if (productType === "") {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Product type required. Use the drop-down menu to select up to 3 categories.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setProductTypeError(true);
    }
    else if (identifier === "") {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Identifier required. Use the text field to enter a descriptive identifier.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setIdentifierError(true);
    }
    else if (!identifier.match(/^[0-9a-zA-Z.]+$/)) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Invalid identifier format. Use the text field to enter a descriptive identifier using only alphanumeric characters and periods.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setIdentifierError(true);
    }
    else if (specificKey === "") {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Specific key required. Use the text field to enter a descriptive specific key.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setSpecificKeyError(true);
    }
    else if (!specificKey.match(/^[0-9a-zA-Z.]+$/)) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Invalid specific key format. Use the text field to enter a descriptive specific key using only alphanumeric characters and periods.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setSpecificKeyError(true);
    }
    else if (dateAdded === "") {
      snackStateCopy[0] = true;
      snackStateCopy[1] = 'Date required. Use the text field to enter a date in the format "mmm.yy" identifier.';
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setDateAddedError(true);
    }
    else if (dateAdded.length !== 6 || allowedDates.indexOf(dateAdded.substring(0, 4)) === -1 || !dateAdded.substring(4, 6).match(/^[0-9]+$/)) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = 'Invalid date format. Use the text field to enter a date in the format "mmm.yy" identifier.';
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setDateAddedError(true);
    }
    else if (productName === "") {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Product name required. Use the text field to enter the product name as it appears on its packaging.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setProductNameError(true);
    }
    else if (quantity <= 0) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Invalid quantity. Use the text field to enter a positive product quantity.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
      setQuantityError(true);
    }
    else if (imageSrc === false) {
      snackStateCopy[0] = true;
      snackStateCopy[1] = "Product image required. Use the camera button take an image of the product being added.";
      snackStateCopy[2] = "error";
      snackStateCopy[3] = "error";
    }
    // Successful error checking, then continue adding product.
    else {
      addProductToInventory(genres, productType, identifier, specificKey, dateAdded, productName, quantity, imageSrc).then(() => {
        createInventoryList(idb).then((resolvedList) => {
          processInventoryList(resolvedList).then((imageList) => {
            // Timeout hack to prevent rows from loading before images.
            const timer = setTimeout(() => setRowsChild(imageList), 500);
            return () => clearTimeout(timer);
          });
        });
      });

      // Set snack state.
      snackStateCopy[0] = true;
      snackStateCopy[1] = `New product successfully added to inventory under SKU "${productType}-${identifier}-${specificKey}-${dateAdded}"!`;
      snackStateCopy[2] = "success";
      snackStateCopy[3] = "primary";

      // Clear values.
      setImageSrc(false);
      setProductType("");
      setIdentifier("");
      setSpecificKey("");
      setDateAdded(todayDate());
      setProductName("");
      setQuantity(0);
      setGenres([]);
    }
    setSnackStateChild(snackStateCopy);
  };

  return (
    <Card>
      <Box sx={{ width: "100%", p: 3, }}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="space-between"
        >
          <CardHeader sx={{ p: 0 }} title="Generate SKU" />
          <Stack
            direction="row"
            spacing={3}
            sx={{ w: 1, }}
          >
            <TextField
              label="Product type"
              defaultValue={""}
              select fullWidth
              onChange={(event) => {
                setProductType(event.target.value);
                setProductTypeError(false);
              }}
              value={productType}
              error={productTypeError}
            >
              <MenuItem key={"manga"} value={"manga"}>manga</MenuItem>
              <MenuItem key={"figure"} value={"figure"}>figure</MenuItem>
              <MenuItem key={"misc"} value={"misc"}>misc</MenuItem>
              <MenuItem key={"snack"} value={"snack"}>snack</MenuItem>
            </TextField>
            <TextField
              label="Identifier"
              helperText="Ex. kny, jk, op, ecchi, etc."
              fullWidth
              value={identifier}
              onChange={(event) => {
                setIdentifier(event.target.value);
                setIdentifierError(false);
              }}
              error={identifierError}
            />
            <TextField
              label="Specific key"
              helperText="Ex. vol1, tanjiro.mini, sls.chopsticks, etc."
              fullWidth
              value={specificKey}
              onChange={(event) => {
                setSpecificKey(event.target.value);
                setSpecificKeyError(false);
              }}
              error={specificKeyError}
            />
            <TextField
              label="Date"
              value={todayDate()}
              helperText="Format: mmm.yy. Ex. jul.23, aug.22, oct.19, etc."
              fullWidth
              onChange={(event) => {
                setDateAdded(event.target.value);
                setDateAddedError(false);
              }}
              error={dateAddedError}
            />
          </Stack>
          <CardHeader sx={{ p: 0 }} title="Add details" />
          <Stack
            direction="row"
            spacing={3}
            sx={{ w: 1, }}
          >
            <TextField
              label="Product name"
              fullWidth
              value={productName}
              helperText="Note: this value will appear on customer packing slips."
              onChange={(event) => {
                setProductName(event.target.value);
                setProductNameError(false);
              }}
              error={productNameError}
            />
          </Stack>  
          <Stack
            direction="row"
            spacing={3}
            sx={{ w: 1, }}
          >
            <FormControl fullWidth>
              <InputLabel id="categories-select-label">Categories</InputLabel>
              <Select
                labelId="categories-select-label"
                id="categories-select"
                multiple
                value={genres}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Categories"/>}
                MenuProps={MenuProps}
                error={genresError}
              >
                {genresList.map((genreOption) => (
                  <MenuItem
                    key={genreOption}
                    value={genreOption}
                    style={getStyles(genreOption, genres, theme)}
                  >
                    {genreOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={quantity}
              onChange={(event) => {
                setQuantity(event.target.value);
                setQuantityError(false);
              }}
              error={quantityError}
            />        
            <Button variant="contained" sx={{ textTransform: "none", }} onClick={handleClickOpen}>
              <AddAPhotoIcon/>
            </Button> 
          
          </Stack>          
          
        </Stack>
      </Box>
      
      <Divider/>

      <Box sx={{ width: "100%", p: 3, }}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ textTransform: "none", }} onClick={handleAddProduct}>
            Add product to inventory
          </Button>
        </Stack>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="photo-dialog" sx={{ px: 6, py: 3, }}>
          {"Center product in frame"}
        </DialogTitle>
        <DialogContent sx={{  p: 0, }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 6, }}
          >
            <Webcam
              audio={false}
              height={360}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={360}
              videoConstraints={videoConstraints}
            />
            {imageSrc === false ? (
              <Button variant="outlined" sx={{ height: 360, minWidth: 360, pointerEvents: "none", textTransform: "none", }}>Photo will appear here</Button>
            )
            : (
              <img src={imageSrc} alt="Captured product" width="360" height="360" />
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 3, }}>
          <Button variant="contained" onClick={capture}>
            Capture
          </Button>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
